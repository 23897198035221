import { INetworkInfo, ISupportToken } from "~/services/api/session/type";
import { getUserTokens } from "~/services/api/token/api";
import { getTokenPrice } from "~/services/BinancePrice";
import * as wChains from "viem/chains";
import { useWalletStore } from "~/services/WalletManager/store";
import { getAssets } from "~/services/tonkeeper";

export const useTokens = defineStore("tokens", () => {
  const tokens = ref<ISupportToken[]>([]);
  const tokensOfCurrentWallet = ref<ISupportToken[]>([]);
  const wallets = useWalletStore();
  const { getSetting } = useSettingStore();

  async function init() {
    if (tokens.value.length > 0) return;
    await update();
  }

  watch(() => wallets.getCurrentWallet()?.smart_address, updateWalletTokens);

  async function update() {
    const listToken = await getUserTokens();
    const networks: { [key: string]: INetworkInfo } = getSetting().remoteConfig.supported_networks;
    let count = 0;
    tokens.value = [];
    listToken.forEach(async (token) => {
      token.chain = networks[token.network];
      token.network_label = networks[token.network].name;
      token.chain_id = networks[token.network].chain_id;
      token.rpc_url = Object.values(wChains).find((c) => c.id == networks[token.network].chain_id)?.rpcUrls.default.http[0] || "";
      token.price_usd = await getTokenPrice(token.symbol);

      tokens.value.push(token);
      count++;
      if (count == listToken.length) updateWalletTokens();
    });
  }

  async function updateTonNetworkTokens() {
    const tonwallet = wallets.getCurrentWallet();
    tokensOfCurrentWallet.value = [];
    const assets = await getAssets(tonwallet?.address || "");
    console.log("ton assets", assets);
    tokensOfCurrentWallet.value = assets;
  }

  async function updateWalletTokens() {
    let count = 0;
    const list: ISupportToken[] = [];
    tokensOfCurrentWallet.value = [];
    // if (wallets.getCurrentWallet().blockchain === "ton") updateTonNetworkTokens();
    tokens.value.forEach(async (token) => {
      const isTokenEVM = !token.network.includes(":");
      const isCurrentWalletEVM = wallets.getCurrentWallet().address.startsWith("0x");
      if (isCurrentWalletEVM !== isTokenEVM) {
        count++;
        return;
      }
      const newBalance = await getBalanceOfToken({
        contractAddress: token.contract_address,
        userAddress: wallets.getCurrentWallet().smart_address,
        chainID: token.chain_id,
        decimal: token.decimal,
        standard: token.contract_standard,
      });
      list.push({
        ...token,
        balance: Number(newBalance),
        balance_usd: Number(newBalance) * Number(token.price_usd),
      });
      count++;

      if (count == tokens.value.length) tokensOfCurrentWallet.value = list.sort((a, b) => b.balance_usd - a.balance_usd);
    });
  }

  return {
    init,
    update,
    updateWalletTokens,
    tokens: computed(() => tokens.value),
    tokensOfCurrentWallet: computed(() => tokensOfCurrentWallet.value),
  };
});
