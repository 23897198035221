import * as Sentry from "@sentry/nuxt";

const {
    public: { ENV },
} = useRuntimeConfig();

Sentry.init({
    dsn: "https://4601ee4830310e0aa93b784afe8faca0@o184064.ingest.us.sentry.io/4507939120414720",
    environment: ENV,
    enabled: ENV !== "development",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});
