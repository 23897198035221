import { fromNano } from "@ton/core";
import { ISupportToken } from "../api/session/type";
import { getCache, setCache } from "../cache";
import axios from "axios";
import { formatUnits } from "viem";
import { IEmulateRisk, TEmulateEvent } from "./models";
import { Base64, ConnectEvent, DisconnectEvent, hexToByteArray, RpcMethod, SessionCrypto, WalletResponse } from "@tonconnect/protocol";
import { TON_BRIDGE_API, TON_DEFAULT_TTL } from "~/constants/contract";

export interface Asset {
  address: string;
  balance: bigint;
  priceInTON?: number;
  priceInUSD?: number;
  worthInTON?: number;
  worthInUSD?: number;
  jetton?: any;
  type: "jetton" | "native";
}

export function toBigInt(val: number, decimals = 9): bigint {
  const factor = 10 ** decimals;
  return BigInt(Math.round(val * factor));
}

export function fromBigInt(val: bigint, decimals = 9): number {
  const divisor = BigInt(10 ** decimals);
  return Number(val) / Number(divisor);
}

export const NATIVE_TOKEN_RAW_ADDRESS = "0:0000000000000000000000000000000000000000000000000000000000000000";
export const NATIVE_TOKEN_ADDRESS = "EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c";
export const NATIVE_EVM_ADDRESS = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
export const NATIVE_TOKEN_SYMBOL = "TON";
export const USDT_TOKEN_RAW_ADDRESS = "0:b113a994b5024a16719f69139328eb759596c38a25f59028b146fecdc3621dfe";
export const USDT_TOKEN_ADDRESS = "EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs";
export const USDT_TOKEN_SYMBOL = "USDT";
export const TON_CHAIN_ID = -10;

export const REF_COOKIE_KEY = "ref";

export function isNativeAddress(address: string): boolean {
  return address == NATIVE_TOKEN_ADDRESS || address == NATIVE_TOKEN_RAW_ADDRESS;
}

export interface BalanceRecord {
  balance: string;
  price: {
    prices: {
      TON: number;
      USD: number;
    };
  };
  wallet_address: {
    address: string;
    is_scam: boolean;
    is_wallet: boolean;
  };
  jetton: {
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    image: string;
    verification: string;
  };
}

const CACHE_EXPIRE_SECONDS = 60;
export const TON_ELEMENT = {
  address: NATIVE_TOKEN_ADDRESS,
  name: "TON",
  symbol: "TON",
  decimals: 9,
  image: "https://s3.coinmarketcap.com/static/img/portraits/6304d4f7dcf54d0fb59743ba.png",
  verification: "whitelist",
};
export const TON_CHAIN = {
  chain_id: -10,
  icon_url: "https://s3.coinmarketcap.com/static/img/portraits/6304d4f7dcf54d0fb59743ba.png",
  token_symbol: "TON",
  rpc_url: "",
  name: "TON",
  key: "ton:mainnet",
  support_multi_send: false,
};

function findInElement(event: any) {
  // JettonSwap - amount_in - amount_out
  // JettonTransfer - amount
  // SmartContractExec
  // TonTransfer
}

function findAssetsRemove(risk: IEmulateRisk) {
  return {
    ton: {
      value: formatUnits(BigInt(risk.ton), 9),
      asset: TON_ELEMENT,
    },
    otherAssets: risk.jettons.map((item) => ({
      value: formatUnits(BigInt(item.quantity), item.jetton.decimals),
      asset: item.jetton,
    })),
  };
}

function caculateFee(trace: any): number {
  if (!trace) return 0;
  const tx = trace.transaction;
  const txFee = Number(fromNano(tx.total_fees || 0));
  Number(fromNano(tx.compute_phase?.gas_fees || 0)) +
    Number(fromNano(tx.storage_phase?.fees_collected || 0)) +
    Number(fromNano(tx.action_phase?.fwd_fees || 0)) +
    Number(fromNano(tx.action_phase?.total_fees || 0));
  return (
    txFee +
    ((trace.children || []) as any[]).reduce((total, child) => {
      return total + caculateFee(child);
    }, 0)
  );
}

export async function emulateTransactionBoc(boc: string) {
  try {
    const infor = await axios<{ event: any; risk: IEmulateRisk; trace: { transaction: any; children: any[] } }>(
      `https://tonapi.io/v2/wallet/emulate`,
      {
        method: "POST",
        data: {
          boc,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!infor.data.event) throw new Error("no event");
    if (!infor.data.event.account) throw new Error("no account");

    const actions = infor.data.event?.actions as any[];
    const actionFrom = actions[0][actions[0].type];
    if (!actionFrom) throw new Error("no actionFrom");

    const gasFee = caculateFee(infor.data.trace);

    const assetRemoves = findAssetsRemove(infor.data.risk);

    const emulate = {
      assetRemoves,
      gasFee: gasFee,
    };

    return emulate;
  } catch (error) {
    console.log("emulateTransactionBoc error", error);
    return {};
  }
}

export async function getAssets(
  wallet: string,
  cacheSeconds?: number,
  forceReload?: boolean,
  useExpiredDataOnError?: boolean
): Promise<ISupportToken[]> {
  const cacheKey = `wallet_assets:jettons:${wallet}`;
  let cachedData: ISupportToken[] | undefined;
  if (!forceReload) {
    cachedData = getCache<ISupportToken[]>(cacheKey);
    if (cachedData) {
      console.log("Return cached assets");
      return cachedData;
    }
  }

  const tonPrice = await getTonPrice();

  const jettons = fetch(`https://tonapi.io/v2/accounts/${wallet}/jettons?currencies=TON,USD`)
    .then((resp) => resp.json())
    .then((data) => data.balances)
    .then((data) => {
      const listTokens: ISupportToken[] = [];
      (data || []).forEach((record: BalanceRecord) => {
        console.log("record", record);
        if (!record) return;
        const floatBalance = Number(fromNano(record.balance));
        const item: ISupportToken = {
          balance: floatBalance,
          contract_address: record.jetton.address,
          balance_usd: floatBalance * record.price.prices.USD,
          chain: TON_CHAIN,
          contract_standard: record.jetton.address === NATIVE_TOKEN_RAW_ADDRESS ? "native" : "erc20",
          decimal: record.jetton.decimals,
          chain_id: -10,
          icon_url: record.jetton.image,
          label: record.jetton.name,
          network: "ton:mainnet",
          network_label: "TON",
          price_usd: record.price.prices.USD.toString(),
          symbol: record.jetton.symbol,
          rpc_url: "",
        };
        listTokens.push(item);
      });
      return listTokens;
    });

  const ton = fetch(`https://toncenter.com/api/v3/wallet?address=${wallet}`)
    .then((resp) => resp.json())
    .then((data) => {
      const balance = data?.balance ? Number(fromNano(data.balance)) : 0;
      const item: ISupportToken = {
        balance,
        contract_address: NATIVE_TOKEN_RAW_ADDRESS,
        price_usd: tonPrice.toString(),
        balance_usd: balance * tonPrice,
        chain: TON_CHAIN,
        chain_id: TON_CHAIN.chain_id,
        network_label: TON_CHAIN.name,
        contract_standard: "native",
        decimal: 9,
        icon_url: "https://s3.coinmarketcap.com/static/img/portraits/6304d4f7dcf54d0fb59743ba.png",
        label: "TON",
        network: "ton:mainnet",
        rpc_url: "",
        symbol: "TON",
      };
      return item;
    });

  return Promise.all([jettons, ton])
    .then((results) => results.flat(1))
    .then((records) => {
      setCache(cacheKey, records, cacheSeconds || CACHE_EXPIRE_SECONDS);
      return records;
    })
    .catch((err) => {
      if (useExpiredDataOnError && cachedData) {
        console.log("Failed to fetch fresh assets: ", err);
        return cachedData;
      }
      throw err;
    });
}

const TON_PRICE_CACHE_KEY = "ton_price";
const TON_PRICE_CACHE_SECONDS = 120;

export async function getTonPrice(): Promise<number> {
  const cachedValue = getCache<number>(TON_PRICE_CACHE_KEY);
  if (cachedValue) return cachedValue;
  const tonPrice = await fetch(`https://keeper.tonapi.io/v2/rates?tokens=ton&currencies=usd`)
    .then((res) => res.json())
    .then((data) => data?.rates?.TON?.prices?.USD)
    .catch((err) => {
      console.log("Failed to fetch TON price: ", err);
      return 0;
    });
  setCache(TON_PRICE_CACHE_KEY, tonPrice, TON_PRICE_CACHE_SECONDS);
  return tonPrice;
}

const WALLET_NAME_CACHE_KEY_PREFIX = "wallet_name";

export async function getWalletName(walletAddress?: string): Promise<string | undefined> {
  if (!walletAddress) return undefined;
  const key = `${WALLET_NAME_CACHE_KEY_PREFIX}:${walletAddress}`;
  const cachedValue = getCache<string>(key);
  if (cachedValue) return cachedValue;
  return fetch(`https://tonapi.io/v2/accounts/${walletAddress}`)
    .then((resp) => resp.json())
    .then((data) => data?.name)
    .then((name) => {
      if (name) setCache(key, name, 86400 * 30);
      else setCache(key, name, 86400);
      return name;
    });
}

export async function sendTonResponse<T extends RpcMethod>(
  response: WalletResponse<T> | ConnectEvent | DisconnectEvent,
  sessionCrypto: SessionCrypto,
  clientSessionId: string,
  ttl?: number
): Promise<void> {
  try {
    const url = `${TON_BRIDGE_API}/message?client_id=${sessionCrypto.sessionId}&to=${clientSessionId}&ttl=${ttl || TON_DEFAULT_TTL}`;

    const encodedResponse = sessionCrypto.encrypt(JSON.stringify(response), hexToByteArray(clientSessionId));

    await fetch(url, {
      body: Base64.encode(encodedResponse),
      method: "POST",
    });
  } catch (e) {
    console.log("send fail", e);
  }
}
