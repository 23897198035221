import { hashMessage, hashTypedData, PrivateKeyAccount, type Hex } from "viem";
import { generatePrivateKey, privateKeyToAccount, publicKeyToAddress } from "viem/accounts";
import { WALLET_TYPE } from "./type";
import { BLOCKCHAIN_TYPE, IProviderData, IUserWallet } from "../api/auth/type";

export function createImportWallet({
  pvk,
  seed,
  userWallet,
  provider_data,
}: {
  seed?: string;
  pvk: Hex;
  userWallet: IUserWallet;
  provider_data?: IProviderData;
}) {
  const account = ref<PrivateKeyAccount | null>(privateKeyToAccount(pvk.includes("0x") ? pvk : `0x${pvk}`));

  async function signMessage(message: string, option?: { hash?: boolean }) {
    if (option?.hash) {
      return account.value?.sign({hash: message as Hex})
    }
    const signature = (await account.value?.signMessage({ message })) || "";
    return signature as Hex;
  }
  async function signTypedData(payload: any, provider = "EVM") {
    const signature = (await account.value?.signTypedData(payload)) || "";

    return signature as `0x${string}`;
  }

  async function generateWallet() {
    const pvk = generatePrivateKey();
    const _acc = privateKeyToAccount(pvk);
    account.value = _acc;
    return _acc;
  }

  async function clear(id: string) {
    account.value = null;
  }

  return {
    address: computed(() => {
      return account.value?.address;
    }),
    get smart_data() {
      return {
        provider_data: provider_data || ({} as IProviderData),
      };
    },
    get smart_address() {
      return account.value?.address;
    },
    wallet_type: "normal" as WALLET_TYPE,
    blockchain: "traditional" as BLOCKCHAIN_TYPE,
    name: userWallet.name || "Imported",
    smart_name: "Smart Imported",
    is_smart: false,
    secretKey: seed || pvk,
    signMessage,
    generateWallet,
    signTypedData,
    getSavedWallet: () => account.value,
    clear,
    tgCloudBackuped: true,
  };
}
