import { defineStore } from "pinia";

export interface IConnectWithAddress {
  address: string;
  topic: string;
}

export const useConnectStore = defineStore("connect_store", () => {
  const connectWithAddresses = useLocalStorage<IConnectWithAddress[]>("connect_with_addresses", []);

  function addConnectWithAddress(address: string, topic: string) {
    const sessions = window.web3Wallet.getActiveSessions();
    const sessionValue = Object.values(sessions);

    connectWithAddresses.value = connectWithAddresses.value.filter((item) => sessionValue.find((session) => session.topic === item.topic));

    connectWithAddresses.value.push({ address, topic });
  }

  function findAddressConnectWithTopic(topic: string) {
    const item = connectWithAddresses.value.find((item) => item.topic === topic);
    return item?.address || "";
  }

  return {
    addConnectWithAddress,
    findAddressConnectWithTopic,
  };
});
