import { PrivateKeyAccount, type Hex } from "viem";
import { generatePrivateKey, privateKeyToAccount, publicKeyToAddress } from "viem/accounts";
import Crypto from "../encrypt/EncryptionService";
import PasswordService from "../security/PasswordService";
import { WALLET_TYPE } from "./type";
import { BLOCKCHAIN_TYPE } from "../api/auth/type";

export function useNormalWallet() {
  const account = ref<PrivateKeyAccount | null>(null);
  const auth = useAuthStore();
  const smart_data = computed(() => auth.getUser().smart_wallets?.find((w) => !w.blockchain));

  async function signMessage(message: string, option?: { hash?: boolean }) {
    if (!account.value) {
      account.value = getSavedWallet();
    }
    const msg = option?.hash ? { raw: message as Hex } : message;
    const signature = (await account.value?.signMessage({ message: msg })) || "";

    return signature as `0x${string}`;
  }
  async function signTypedData(payload: any, provider = "EVM") {
    if (!account.value) {
      account.value = getSavedWallet();
    }
    const signature = (await account.value?.signTypedData(payload)) || "";

    return signature as `0x${string}`;
  }

  function getSavedWallet() {
    const smart_wallet = auth.getUser().smart_wallets?.find((w) => !w.blockchain);
    if (!smart_wallet) throw new Error("Normal Wallet not found");
    const pwd = PasswordService.getPassword();
    if (!pwd) throw new Error("Password not found");
    const pvk = Crypto.decrypt(smart_wallet.encrypted_credential, pwd);
    if (!pvk) throw new Error("Password not found");
    const _account = privateKeyToAccount(pvk as any);
    account.value = _account;

    return account as any;
  }

  async function generateWallet() {
    const pvk = generatePrivateKey();
    const _acc = privateKeyToAccount(pvk);
    account.value = _acc;
    return _acc;
  }

  async function clear(id: string) {
    account.value = null;
  }

  return {
    address: computed(() => {
      return account.value?.address;
    }),
    get smart_data() {
      return smart_data.value;
    },
    get smart_address() {
      return smart_data.value?.smart_address;
    },
    wallet_type: "normal" as WALLET_TYPE,
    blockchain: "normal" as BLOCKCHAIN_TYPE,
    name: auth.getUser().user_wallets?.find((w) => w.address === account.value?.address)?.name || "Normal Wallet",
    smart_name: "EVM Smart Wallet",
    is_smart: true,
    signMessage,
    generateWallet,
    signTypedData,
    getSavedWallet,
    clear,
    tgCloudBackuped: true,
  };
}
