<script lang="ts" setup>
import { ExpandedViewport, useWebAppBackButton, useWebAppBiometricManager, useWebAppNavigation, useWebAppTheme } from "vue-tg";

const appSetting = useAppSetting();
const router = useRouter();
const biometric = useWebAppBiometricManager();
const { onBackButtonClicked, isBackButtonVisible } = useWebAppBackButton();
const { openLink } = useWebAppNavigation();
const route = useRoute();

useWebAppTheme().setHeaderColor("#351616");

onMounted(() => {
  window.openLink = openLink;
  console.log("mini app");
  biometric.initBiometric(() => {
    console.log("init biometric done");
    appSetting.saveLoadBiometric();
  });

  onBackButtonClicked(
    () => {
      router.back();
    },
    {
      manual: true,
    }
  );
  const userAgent = navigator?.userAgent || "";
  window.isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
  window.isAndroid = userAgent.toLocaleLowerCase().includes("android");
  try {
    Object.defineProperty(navigator, "userAgent", {
      get: function () {
        return userAgent.replaceAll("Android", "");
      },
    });
  } catch (error) {}
});

watch(
  () => route.fullPath,
  function () {
    if (route.path === "/" || route.path === "/dashboard" || route.path === "/onboard") {
      history.state && history.replaceState(null, "", route.fullPath);
    }
    isBackButtonVisible.value = history.state && !!history.state.back;
  }
);
</script>
<template>
  <ExpandedViewport />
</template>
