import { IBrowserConnected, IMetadata } from "~/types";

export const useBrowserConnection = defineStore("browser-connection", () => {
  const listConnectDiscover = useLocalStorage<{ [domain: string]: IBrowserConnected }>("list_browser_connected", {});

  function saveConnected(metadata: IMetadata, address: string, chainId: number) {
    const domain = new URL(metadata.url).hostname;

    listConnectDiscover.value[domain] = {
      domain,
      address,
      chainId,
      metadata: { ...metadata, name: metadata.name || domain },
      timestamp: Date.now(),
    };
  }

  function getConnected(domain: string) {
    return listConnectDiscover.value[domain];
  }

  function updateConnected(domain: string, connected: IBrowserConnected) {
    listConnectDiscover.value[domain] = {
      ...listConnectDiscover.value[domain],
      ...connected,
    };
  }

  function deleteConnected(domain: string) {
    delete listConnectDiscover.value[domain];
  }

  return {
    listBrowserConnected: computed(() => Object.values(listConnectDiscover.value)),
    saveConnected,
    getConnected,
    updateConnected,
    deleteConnected,
  };
});
