import crypto from "crypto-js";

class ModuleCrypto {
  wEncryptKey = "";

  encrypt(value: string, key?: string) {
    return crypto.AES.encrypt(value, key || this.wEncryptKey).toString();
  }

  encryptObject(params: Record<string, unknown>, key?: string) {
    return crypto.AES.encrypt(JSON.stringify(params), key || this.wEncryptKey).toString();
  }

  decrypt(enc: string, key?: string) {
    return crypto.AES.decrypt(enc, key || this.wEncryptKey).toString(crypto.enc.Utf8);
  }

  decryptObject(enc: string, key?: string) {
    const objStr = crypto.AES.decrypt(enc, key || this.wEncryptKey).toString(crypto.enc.Utf8);
    return JSON.parse(objStr);
  }
}

const Crypto = new ModuleCrypto();

export default Crypto;
