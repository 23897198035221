import { hexToBytes, PrivateKeyAccount, type Hex } from "viem";
import { generatePrivateKey, privateKeyToAccount, publicKeyToAddress } from "viem/accounts";
import Crypto from "../encrypt/EncryptionService";
import PasswordService from "../security/PasswordService";
import { WALLET_TYPE } from "./type";
import { BLOCKCHAIN_TYPE } from "../api/auth/type";
import { OpenedContract, TonClient, WalletContractV4, Cell } from "@ton/ton";
import { KeyPair, mnemonicNew, mnemonicToPrivateKey, sha256_sync, sign } from "@ton/crypto";
import { postImportWallet } from "../api/wallet/api";
import { createSmartWallet, getUserInfo } from "../api/auth/api";
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { createTonUtils } from "../tonkeeper/utils";

export function createTonWallet() {
  const account = ref<WalletContractV4 | null>(null);
  const auth = useAuthStore();
  const smart_data = computed<any>(() => ({}));
  const keypair = ref<KeyPair | null>(null);
  const contract = ref<OpenedContract<WalletContractV4> | null>(null);
  const client = ref<TonClient | null>(null);
  const tonUtilRef = ref<ReturnType<typeof createTonUtils> | null>(null);
  const secretKey = ref<string>("");

  function getTonUtils() {
    if (!account.value || !keypair.value) return null;
    if (tonUtilRef.value) return tonUtilRef.value;
    // @ts-ignore
    tonUtilRef.value = createTonUtils({ account: account.value, keypair: keypair.value });
    return tonUtilRef.value;
  }

  async function getTonClient() {
    if (client.value) return client.value;
    const endpoint = await getHttpEndpoint();
    const _client = new TonClient({
      endpoint: endpoint,
    });
    client.value = _client;
    return _client;
  }

  async function getNativeBalance() {
    return getTonUtils()?.getNativeBalance();
  }

  async function sendTransaction(params: { to: string; value: string; contract_address: string }) {
    if (!keypair.value) throw new Error("KeyPair not found");
    if (!account.value) throw new Error("Account not found");
    if (!contract.value) throw new Error("Contract not found");
    return getTonUtils()?.sendTransaction(params);
  }

  async function signMessage(message: string, option?: { hash?: boolean }) {
    if (!keypair.value) throw new Error("KeyPair not found");

    if (!account.value) throw new Error("Account not found");
    if (!contract.value) throw new Error("Contract not found");
    return "0x0" as `0x${string}`;
  }

  function getSigner() {
    return async (message: Cell) => {
      if (!keypair.value?.secretKey) throw new Error("KeyPair not found");

      const signature = sign(message.hash(), keypair.value?.secretKey);

      return signature;
    };
  }
  async function signTypedData(payload: any, provider = "EVM") {
    if (!account.value) {
      account.value = await getSavedWallet();
    }
    return "0x" as any;
  }

  async function getSavedWallet(seed?: string): Promise<WalletContractV4 | null> {
    if (seed) {
      const mneStr = seed;
      const mnemonics = mneStr.includes("-") ? mneStr.split("-") : mneStr.includes(" ") ? mneStr.split(" ") : [];
      if (mnemonics.length < 10) throw new Error("Invalid Mnemonic");
      const _keyPair = await mnemonicToPrivateKey(mnemonics);

      keypair.value = _keyPair;

      const wallet = WalletContractV4.create({ workchain: 0, publicKey: _keyPair.publicKey });

      account.value = wallet;

      const _client = await getTonClient();
      const _contract = _client.open(wallet);
      contract.value = _contract;
      secretKey.value = seed;
      return wallet;
    }
    return null;
  }

  async function generateWallet({ seed, name }: { seed?: string; name: string }) {
    // Generate new key
    if (!seed || seed.split(" ").length !== 24) {
      throw new Error("Invalid Mnemonic");
    }
    try {
      const mnemonics = seed?.split(" ") || (await mnemonicNew());

      const _keyPair = await mnemonicToPrivateKey(mnemonics);
      keypair.value = _keyPair;

      const workchain = 0; // Usually you need a workchain 0
      const wallet = WalletContractV4.create({ workchain, publicKey: _keyPair.publicKey });
      const address = wallet.address.toString();
      account.value = wallet;

      const _client = await getTonClient();
      const _contract = _client.open(wallet);

      contract.value = _contract;
      console.log("generate ton wallet", address);
      const userWallet = await postImportWallet(address, name || "Ton Wallet");
      // const res = await createSmartWallet(enc, wallet.address.toString(), "", "ton", "");
      if (userWallet) {
        await getUserInfo();
      }

      secretKey.value = seed;

      return wallet;
    } catch (error) {
      throw new Error("Create ton wallet failed");
    }
  }

  async function clear(id: string) {
    account.value = null;
  }

  return {
    address: computed(() => {
      return (
        account.value?.address?.toString({
          bounceable: false,
        }) || ""
      );
    }),
    get smart_data() {
      return smart_data.value;
    },
    get smart_address() {
      return (
        account.value?.address?.toString({
          bounceable: false,
        }) || ""
      );
    },
    wallet_type: "ton_normal" as WALLET_TYPE,
    blockchain: "ton" as BLOCKCHAIN_TYPE,
    name: auth.getUser().user_wallets?.find((w) => w.address === account.value?.address.toString())?.name || "Ton Wallet",
    smart_name: "EVM Smart Wallet",
    is_smart: false,
    signMessage,
    generateWallet,
    signTypedData,
    getSavedWallet,
    clear,
    tgCloudBackuped: true,
    sendTransaction,
    getNativeBalance,
    secretKey: computed(() => secretKey.value),
    publicKey: computed(() => keypair.value?.publicKey),
    privateKey: computed(() => keypair.value?.secretKey),
    contract: computed(() => contract.value),
    getSigner,
  };
}
