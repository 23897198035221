import { toast } from "~/components/ui/toast";
import Fetch from "..";
import errorHandler from "../ErrorHandler";

export async function updateMpcBackup(params: any, key_id: string) {
  try {
    const { data } = await Fetch.put<any>(`@api/mpc/backup-info`, {
      data: params,
      key_id: key_id,
    });
    return data.data;
  } catch (error) {
    console.log("uploadMpcBackup er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: "Backup MPC error",
      description: msg,
      duration: 3000,
    });
    return null;
  }
}

export async function updateTgCloudData(dataStr: string) {
  try {
    const { data } = await Fetch.put<any>(`@api/mpc/tg-cloud-data`, {
      data: {
        data: dataStr,
      },
    });
    return true;
  } catch (error) {
    console.log("updateTgCloudData er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: "Backup Cloud error",
      description: msg,
      duration: 3000,
    });
    return false;
  }
}

export async function getTgCloudData() {
  try {
    const { data } = await Fetch.get<{ data: { data: string } }>(`@api/mpc/tg-cloud-data`);
    return data.data.data;
  } catch (error) {
    console.log("getTgCloudData er", error);

    return "";
  }
}
