import { getTokenPrice } from "~/services/BinancePrice";
import Fetch from "..";
import { ICreateTxResponse, ISmartSession } from "../transaction/type";
import { IRemoteConfig, ISessionInfo, ISmartSessionItem, ISupportToken } from "./type";
import * as wChains from "viem/chains";
import { getUserTokens } from "../token/api";

export async function createSessionWithUri(uri: string) {
  try {
    const { data } = await Fetch.post<{ data: any }>(`@api/wallet-sessions/new`, {
      data: {
        uri,
      },
      project_id: "673556c6-8d23-4add-b06a-35189bfbbb94",
    });
    return data.data;
  } catch (error) {
    console.log("fetchDetailCharacter er", error);
    return { access_token: "" };
  }
}

export async function pushProjectMetadata(metadata: any) {
  const sid = localStorage.getItem("stavax_sid");
  if (!sid) return;
  try {
    await Fetch.post(`@api/wallet-sessions/${sid}/connect`, {
      id: sid,
      project_metadata: metadata,
    });
  } catch (error) {
    console.log("pushProjectMetadata er", error);
  }
}

export async function updateSDKConnect(deviceID: string) {
  try {
    await Fetch.post(`@api/wallet-sessions/connect`, {
      sdk_device_id: deviceID
    });
  } catch (error) {
    console.log("updateSDKConnect er", error);
  }
}

export async function getSessionById(id: string) {
  try {
    const { data } = await Fetch.get<{
      data: { data: ISessionInfo; project_id: string; id: string };
    }>(`@api/wallet-sessions/${id}`);

    return data.data;
  } catch (error) {
    console.log("fetchDetailCharacter er", error);
    return null;
  }
}

export async function getRemoteConfig() {
  try {
    const { data } = await Fetch.get<{ data: { value: IRemoteConfig } }>(`@api/remote-configs/stavax-account/app-config`);

    const tokens: ISupportToken[] = await getUserTokens();
    const networks: any = data.data.value.supported_networks;
    const tokenWithNetwork: ISupportToken[] = [];
    for (const token of tokens) {
      token.chain = networks[token.network];
      token.network_label = networks[token.network].name;
      token.chain_id = networks[token.network].chain_id;
      token.rpc_url = Object.values(wChains).find((c) => c.id == networks[token.network].chain_id)?.rpcUrls.default.http[0] || "";
      token.price_usd = await getTokenPrice(token.symbol);
      tokenWithNetwork.push(token);
    }
    return {
      ...data.data.value,
      supported_tokens: tokenWithNetwork,
    };
  } catch (error) {
    console.log("getRemoteConfig er", error);
    return { address: "" };
  }
}

export async function fetchSessionInit(body: ISmartSession) {
  try {
    const { data } = await Fetch.post<{
      data: ICreateTxResponse;
    }>(`@api/smart-wallets/sessions/init`, body);
    return data.data;
  } catch (error) {
    console.log("fetchSessionInit er", error);
    return null;
  }
}

export async function fetchSessionList(filter?: { project_id?: string; smart_address?: string }): Promise<ISmartSessionItem[]> {
  try {
    const { data } = await Fetch.get<{
      data: { items: ISmartSessionItem[] };
    }>(`@api/smart-wallets/sessions`, {
      params: filter,
    });
    return data.data.items;
  } catch (error) {
    console.log("fetchSessionList er", error);
    return [];
  }
}

export async function updateSessionEnable(id: string, status: "active" | "inactive") {
  try {
    const { data } = await Fetch.put<{
      data: ICreateTxResponse;
    }>(`@api/smart-wallets/sessions/${id}/status`, {
      status,
      id,
    });
    return data.data;
  } catch (error) {
    console.log("updateSessionEnable er", error);
    return null;
  }
}

export async function updateTerminateAllSession() {
  try {
    const { data } = await Fetch.post<{
      data: ICreateTxResponse;
    }>(`@api/smart-wallets/sessions/terminate-all`, {});
    return data.data;
  } catch (error) {
    console.log("updateTerminateAllSession er", error);
    return null;
  }
}
