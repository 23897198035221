import {
  Contract,
  OpenedContract,
  TonClient,
  WalletContractV4,
  internal,
  Cell,
  beginCell,
  Address,
  external,
  storeMessage,
  toNano,
  address,
  fromNano,
  storeMessageRelaxed,
  JettonWallet,
  JettonMaster,
  Builder,
  Transaction,
} from "@ton/ton";
import { KeyPair, mnemonicNew, mnemonicToPrivateKey, sign } from "@ton/crypto";
import { Buffer } from "buffer";
import { postImportWallet } from "../api/wallet/api";
import { createSmartWallet, getUserInfo } from "../api/auth/api";
import { getAssets, NATIVE_EVM_ADDRESS, NATIVE_TOKEN_ADDRESS, NATIVE_TOKEN_RAW_ADDRESS } from "../tonkeeper";
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { toast } from "~/components/ui/toast";
import errorHandler from "../api/ErrorHandler";
import { Wallet4SendArgsSigned } from "@ton/ton/dist/wallets/WalletContractV4";
import { formatEther, formatUnits } from "viem";
import { decodeHex } from "#staxmpc/lib/encode";

export interface ITransactionResponse {
  tx_hash: string;
  status: string;
  transaction?: Transaction;
}

export async function getTransactionHash(account: WalletContractV4) {
  const endpoint = await getHttpEndpoint();
  const client = new TonClient({
    endpoint: endpoint,
  });
  return new Promise<ITransactionResponse>(async (resolve, reject) => {
    let inter: any = null;
    try {
      const timeout = setTimeout(() => {
        clearInterval(inter);
        return reject({
          tx_hash: "",
          status: "timeout",
        });
      }, 37000);
      inter = setInterval(async () => {
        const transactions = await client.getTransactions(account.address, { limit: 1 });
        const tran = transactions[0];
        const transNow = tran.now;
        const now = new Date().getTime();
        const hash = tran.hash().toString("hex");

        if (now - transNow * 1000 < 11000) {
          clearInterval(inter);
          clearTimeout(timeout);
          return resolve({
            tx_hash: hash,
            status: tran.endStatus,
            transaction: tran,
          });
        }
      }, 5000);
    } catch (error) {
      console.log("getTransaction error", error);
      if (inter) clearInterval(inter);
      return reject({
        tx_hash: "",
        status: "error",
      });
    }
  });
}

export function createTonUtils({
  account,
  keypair,
  signer,
}: {
  account: WalletContractV4;
  keypair?: KeyPair;
  signer?: (message: Cell) => Promise<Buffer>;
}) {
  const client = ref<TonClient | null>(null);

  async function getTonClient(): Promise<TonClient> {
    if (client.value) return client.value as any;
    const endpoint = await getHttpEndpoint();
    const _client = new TonClient({
      endpoint: endpoint,
    });
    client.value = _client;
    return _client;
  }

  async function getTonContract() {
    const _client = await getTonClient();
    const _contract = _client.open(account);
    return _contract;
  }
  async function getNativeBalance() {
    if (!account) throw new Error("Account not found");
    const _contract = await getTonContract();
    const balance = await _contract.getBalance();
    return Number(fromNano(balance));
  }

  async function getUserJettonWalletAddress(userAddress: string, jettonMasterAddress: string) {
    const _client = await getTonClient();
    const userAddressCell = beginCell().storeAddress(Address.parse(userAddress)).endCell();

    const response = await _client.runMethod(Address.parse(jettonMasterAddress), "get_wallet_address", [{ type: "slice", cell: userAddressCell }]);

    return response.stack.readAddress();
  }

  async function getTokenBalance({
    user_address,
    contract_address,
    decimals = 9,
  }: {
    user_address?: string;
    contract_address?: string;
    decimals?: number;
  }) {
    try {
      const _client = await getTonClient();

      if (!contract_address) {
        const balance = await _client.getBalance(address(user_address || account?.address.toString() || ""));
        return Number(formatUnits(balance, decimals));
      }

      const jettonAddress = await getUserJettonWalletAddress(
        user_address || account?.address.toString() || "",
        contract_address || NATIVE_TOKEN_ADDRESS
      );

      const balance = await _client.open(JettonWallet.create(jettonAddress)).getBalance();

      return Number(formatUnits(balance, decimals));
    } catch (error) {
      console.log("getTokenBalance", contract_address || NATIVE_TOKEN_ADDRESS, error);
      return 0;
    }
  }

  async function sendTokenTransaction(params: {
    to: string;
    value: string;
    contract_address: string;
    addresses?: string[];
  }): Promise<ITransactionResponse> {
    if (!account) throw new Error("Account not found");
    const _contract = await getTonContract();
    if (!_contract) throw new Error("Contract not found");
    const { init } = _contract;
    try {
      const _client = await getTonClient();
      const contractDeployed = await _client.isContractDeployed(Address.parse(account.address.toString()));
      let neededInit: null | typeof init = null;

      if (init && !contractDeployed) {
        neededInit = init;
      }

      console.log("Address.parse(params.to)", Address.parse(params.to));

      const jettonWalletAddress = params.contract_address
        ? await getUserJettonWalletAddress(account?.address.toString() || "", params.contract_address)
        : Address.parse(params.to);
      console.log("jestton address", jettonWalletAddress);
      let messageBody: Builder | Cell = beginCell()
        .storeUint(0x0f8a7ea5, 32) // opcode for jetton transfer
        .storeUint(0, 64) // query id
        .storeCoins(toNano(params.value)) // jetton amount, amount * 10^9
        .storeAddress(address(params.to))
        .storeAddress(account.address)
        .storeBit(0) // no custom payload
        .storeCoins(0) // forward amount - if > 0, will send notification message
        .storeBit(0); // we store forwardPayload as a reference, set 1 and uncomment next line for have a comment
      messageBody = messageBody.endCell();

      const internalMessage = internal({
        to: jettonWalletAddress,
        value: toNano("0.02"),
        bounce: false,
        body: messageBody,
      });
      const seqno = await _contract.getSeqno();

      const body = await account.createTransfer({
        seqno,
        secretKey: keypair?.secretKey,
        signer: signer as any,
        messages: [internalMessage],
      });

      const externalMessage = external({
        to: account.address.toString({ bounceable: false }),
        init: neededInit as any,
        body,
      });
      const externalMessageCell = beginCell().store(storeMessage(externalMessage)).endCell();

      const signedTransaction = externalMessageCell.toBoc();

      await _client.sendFile(signedTransaction);

      return getTransactionHash(account);
    } catch (error) {
      console.log("ton send token error", error);
      return {
        tx_hash: "",
        status: errorHandler(error).getAllMessagesString(),
      };
    }
  }

  async function sendTransaction(params: { to: string; value: string; contract_address: string }): Promise<ITransactionResponse> {
    console.log("ton send transaction", params);
    if (!(params.contract_address === NATIVE_TOKEN_RAW_ADDRESS || params.contract_address === NATIVE_EVM_ADDRESS))
      return sendTokenTransaction(params);
    if (!account) throw new Error("Account not found");
    const _client = await getTonClient();
    const _contract = await getTonContract();

    try {
      console.log("send transaction", _contract, _contract.getSeqno);
      const seqno = await _contract.getSeqno();
      console.log("keypair", keypair);
      const transferParams = keypair
        ? { secretKey: keypair?.secretKey || "" }
        : {
            signer: signer as any,
          };
      const transfer = await account.createTransfer({
        seqno,
        ...transferParams,
        messages: [
          internal({
            ...params,
            bounce: false,
            body: "",
          }),
        ],
      });

      await _client.sendExternalMessage(account as WalletContractV4, transfer);
      return getTransactionHash(account);
    } catch (error) {
      console.log("ton send error", error);
      return {
        tx_hash: "",
        status: errorHandler(error).getAllMessagesString(),
      };
    }
  }

  async function signMessage(message: string, option?: { hash?: boolean }) {
    if (!account) throw new Error("Account not found");

    if (!keypair) throw new Error("KeyPair not found");
    const decode = decodeHex(message);
    const dataHash = Buffer.from(decode);

    const signature = sign(dataHash, keypair?.secretKey);

    return signature.toString("hex");
  }
  async function signTypedData(payload: any, provider = "EVM") {
    if (!account) throw new Error("Account not found");
    return "0x" as any;
  }

  return {
    signMessage,
    signTypedData,
    sendTransaction,
    getNativeBalance,
    getUserJettonWalletAddress,
    getTokenBalance,
  };
}

export const getDomainFromURL = (url: string): string => {
  const parsedURL = new URL(url);
  return parsedURL.host;
};
