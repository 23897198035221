export class CustomStorage {
  table = "";
  constructor(table: string) {
    this.table = table;
  }
  getItem(key: string) {
    const obj = JSON.parse(localStorage.getItem(this.table) || "{}");
    return obj[key];
  }
  async getKeys() {
    return Object.keys(JSON.parse(localStorage.getItem(this.table) || "{}"));
  }
  async setItem(key: string, value: any) {
    const obj = JSON.parse(localStorage.getItem(this.table) || "{}");
    obj[key] = value;
    localStorage.setItem(this.table, JSON.stringify(obj));
  }
  async removeItem(key: string) {
    const obj = JSON.parse(localStorage.getItem(this.table) || "{}");
    delete obj[key];
    localStorage.setItem(this.table, JSON.stringify(obj));
  }
  async getEntries<T>() {
    return Object.entries<T>(JSON.parse(localStorage.getItem(this.table) || "{}"));
  }
}
