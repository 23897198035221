import { CHAIN, ConnectItem, ConnectItemReply, ConnectRequest, TonProofItemReply } from "@tonconnect/protocol";
import TonWeb from "tonweb";
import { Buffer } from "buffer";
import { DAppManifest } from "./models";

import naclUtils from "tweetnacl-util";
import nacl from "tweetnacl";
import { sha256 } from "@ton/crypto";
import { TON_CHAIN_ID } from ".";
import { Signer } from "./contractService";
import { Cell } from "@ton/core";

export class ConnectReplyBuilder {
  request: ConnectRequest;

  manifest: DAppManifest;

  constructor(request: ConnectRequest, manifest: DAppManifest) {
    this.request = request;
    this.manifest = manifest;
  }

  private async createTonProofItem(address: string, signer: Signer, payload: string): Promise<TonProofItemReply> {
    try {
      const timestamp = Date.now();
      const timestampBuffer = Buffer.from(timestamp.toString());

      const domain = new URL(this.manifest.url).hostname;
      const domainBuffer = Buffer.from(domain);
      const domainLengthBuffer = Buffer.allocUnsafe(4);
      domainLengthBuffer.writeInt32LE(domainBuffer.byteLength);

      const [workchain, addrHash] = address.split(":");

      const addressWorkchainBuffer = Buffer.allocUnsafe(4);
      addressWorkchainBuffer.writeInt32BE(Number(workchain));

      const addressBuffer = Buffer.concat([new Uint8Array(addressWorkchainBuffer), new Uint8Array(Buffer.from(addrHash, "hex"))]);

      const messageBuffer = Buffer.concat([
        new Uint8Array(Buffer.from("ton-proof-item-v2/")),
        new Uint8Array(addressBuffer),
        new Uint8Array(domainLengthBuffer),
        new Uint8Array(domainBuffer),
        new Uint8Array(timestampBuffer),
        new Uint8Array(Buffer.from(payload)),
      ]);
      const message = await sha256(messageBuffer);

      const bufferToSign = Buffer.concat([
        new Uint8Array(Buffer.from("ffff", "hex")),
        new Uint8Array(Buffer.from("ton-connect")),
        new Uint8Array(message),
      ]);
      const hash = await sha256(bufferToSign);
      const cell = {
        hash: () => hash,
      } as Cell;

      const signed = await signer(cell);

      const signature = naclUtils.encodeBase64(new Uint8Array(signed));

      // const signature = "";

      return {
        name: "ton_proof",
        proof: {
          timestamp,
          domain: {
            lengthBytes: domainBuffer.byteLength,
            value: domain,
          },
          signature,
          payload,
        },
      };
    } catch (e) {
      console.log("eeeeeee", e);
      return {
        name: "ton_proof",
        error: {
          code: 0,
          // @ts-ignore
          message: `Wallet internal error: ${e.message}`,
        },
      };
    }
  }

  async createReplyItems(
    addr: string,
    signer: Signer,
    publicKey: Uint8Array,
    walletStateInit: string,
    isTestnet: boolean
  ): Promise<ConnectItemReply[]> {
    const address = new TonWeb.utils.Address(addr).toString(false, true, true);

    const replyItems: ConnectItemReply[] = [];
    for (const item of this.request.items) {
      switch (item.name) {
        case "ton_addr":
          replyItems.push({
            name: "ton_addr",
            address,
            network: TON_CHAIN_ID,
            publicKey: Buffer.from(publicKey).toString("hex"),
            walletStateInit,
          });
          break;

        case "ton_proof":
          replyItems.push(await this.createTonProofItem(address, signer, item.payload));
          break;

        default:
          replyItems.push({
            name: (item as ConnectItem).name,
            error: { code: 400 },
          } as unknown as ConnectItemReply);
      }
    }

    return replyItems;
  }

  static createAutoConnectReplyItems(addr: string, publicKey: Uint8Array, walletStateInit: string): ConnectItemReply[] {
    const address = new TonWeb.utils.Address(addr).toString(false, true, true);

    return [
      {
        name: "ton_addr",
        address,
        network: TON_CHAIN_ID,
        publicKey: Buffer.from(publicKey).toString("hex"),
        walletStateInit,
      },
    ];
  }
}
