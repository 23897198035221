import { encodeAbiParameters, encodeFunctionData, parseAbi, concat, Hex } from "viem";
import { IProviderData } from "../api/auth/type";
import { useMPCWallet } from ".";
import { IWalletBase, useWalletStore } from "../WalletManager/store";

const ERC6492_MAGIC_BYTES = "0x6492649264926492649264926492649264926492649264926492649264926492";

async function getFactoryData(eoaAddress: Hex, ecdsaOwnershipModuleAddress: Hex, index: number) {
  const moduleRegistryParsedAbi = parseAbi(["function initForSmartAccount(address owner)"]);
  const initData = encodeFunctionData({
    abi: moduleRegistryParsedAbi,
    functionName: "initForSmartAccount",
    args: [eoaAddress],
  });

  const factoryParsedAbi = parseAbi(["function deployCounterFactualAccount(address moduleSetupContract, bytes moduleSetupData, uint256 index)"]);
  return encodeFunctionData({
    abi: factoryParsedAbi,
    functionName: "deployCounterFactualAccount",
    args: [ecdsaOwnershipModuleAddress, initData, BigInt(index || 0)],
  });
}
// { "domain": {}, "message": { "contents": "Hello World!" }, "primaryType": "Message", "types": { "EIP712Domain": [], "Message": [ { "name": "contents", "type": "string" } ] } }
export async function signWalletMessage(params: {
  message: string;
  isDeployed?: boolean;
  provider_data: IProviderData;
  method?: string;
  rqParams?: any;
  wallet: IWalletBase;
}) {
  const wallet = params.wallet;
  if (!wallet) return "";
  const { factory_address, ecdsa_ownership_module_address, index } = params.provider_data;
  let eoaSig: any = "";
  try {
    // await getWallet();
    if (params.method?.includes("eth_signTypedData")) {
      const ethTyped = JSON.parse(params.rqParams[1]);
      const signTypes = JSON.parse(JSON.stringify(ethTyped.types));
      // TODO
      // @ts-ignore
      eoaSig = (await wallet.signTypedData({
        message: ethTyped.message,
        types: signTypes,
        primaryType: ethTyped.primaryType,
        domain: ethTyped.domain,
      })) as Hex;
    } else {
      console.log("message", params.message);
      eoaSig = (await wallet.signMessage(params.message)) as Hex;
    }
  } catch (error) {
    console.log("eth_signTypedData error", error);
    return "";
  }
  console.log("eoaSig", eoaSig);
  if (!wallet.is_smart) return eoaSig;
  const erc1271Sig = encodeAbiParameters([{ type: "bytes" }, { type: "address" }], [eoaSig, ecdsa_ownership_module_address]);

  if (params.isDeployed) {
    return erc1271Sig;
  }

  const abiEncodedMessage = encodeAbiParameters(
    [
      {
        type: "address",
        name: "create2Factory",
      },
      {
        type: "bytes",
        name: "factoryCalldata",
      },
      {
        type: "bytes",
        name: "originalERC1271Signature",
      },
    ],
    [factory_address, (await getFactoryData(wallet.address!, ecdsa_ownership_module_address, index)) ?? "0x", erc1271Sig]
  );
  return concat([abiEncodedMessage, ERC6492_MAGIC_BYTES]);
}

// const signer = new Wallet("pvk");

// //BICONOMY 2.0.0
// //https://docs.biconomy.io/contracts
// const factoryAddress = '0x000000a56Aaca3e9a4C479ea6b6CD0DbcB6634F5'
// const ecdsaOwnershipModuleAddress = '0x0000001c5b32F37F5beA87BDD5374eB2aC54eA8e';

// signMessage({
//     signer,
//     message: "Hello World!",
//     isDeployed: false,
//     factoryAddress,
//     ecdsaOwnershipModuleAddress,
//     index: 0
// }).then(console.log)
