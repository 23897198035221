import { toast } from "~/components/ui/toast";
import Fetch from "..";
import errorHandler from "../ErrorHandler";
import { IUserWallet } from "../auth/type";
import { IContactItem } from "./type";

export async function postImportWallet(address: string, name: string): Promise<IUserWallet[] | null> {
  try {
    const { data } = await Fetch.post<{ data: IUserWallet[] }>(`@api/user-wallets/import`, {
      address,
      name,
    });
    return data.data;
  } catch (error) {
    console.error("postImportWallet er", error);
    toast({
      description: errorHandler(error).getAllMessagesString(),
      duration: 3000,
    });
    return null;
  }
}

export async function postRenameWallet(address: string, name: string): Promise<IUserWallet | null> {
  try {
    const { data } = await Fetch.post<{ data: IUserWallet }>(`@api/user-wallets/edit-name`, {
      address,
      name,
    });
    return data.data;
  } catch (error) {
    console.error("postRenameWallet er", error);
    toast({
      description: errorHandler(error).getAllMessagesString(),
      duration: 3000,
    });
    return null;
  }
}

export async function postAddContact(address: string, name: string): Promise<IContactItem | null> {
  try {
    const { data } = await Fetch.post<{ data: IContactItem }>(`@api/contacts`, {
      address,
      name,
    });
    return data.data;
  } catch (error) {
    console.error("postAddContact er", error);
    toast({
      description: errorHandler(error).getAllMessagesString(),
      duration: 3000,
    });
    return null;
  }
}

export async function getListContact(): Promise<IContactItem[]> {
  try {
    const { data } = await Fetch.get<{ data: { items: IContactItem[] } }>(`@api/contacts`);

    return data.data.items;
  } catch (error) {
    console.log("getListContact er", error);
    return [];
  }
}

export async function deleteContact(id: string) {
  try {
    const { data } = await Fetch.delete<{ data: IUserWallet[] }>(`@api/contacts/${id}`);
    return true;
  } catch (error) {
    console.error("deleteContact er", error.response.status);
    toast({
      description: errorHandler(error).getAllMessagesString(),
      duration: 3000,
    });
    return false;
  }
}
