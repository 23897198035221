<script setup lang="ts">
onMounted(() => {
  const starsMoving = document.getElementById("stars-moving");
  if (!starsMoving)
    return;

  // constants
  const COLOR_SPACE = "black";
  const COLOR_STARS = "white";
  const STAR_NUM = 160; // number of stars in the starfield
  const STAR_SIZE = 0.002; // max star size as a fraction of screen width
  const STAR_SPEED = 0.01; // fraction of screen width per second

  // set up the canvas and context
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.height = starsMoving.clientHeight;
  canvas.width = starsMoving.clientWidth;
  starsMoving.appendChild(canvas);

  // set up the stars
  const stars: any[] = [];
  const starSpeed = STAR_SPEED * canvas.width;
  const xv = starSpeed * randomSign() * Math.random();
  // Using Pythagoras' theorem, yv = sqrt(starSpeed^2 - xv^2)
  const yv = Math.sqrt(starSpeed ** 2 - xv ** 2) * randomSign();
  for (let i = 0; i < STAR_NUM; i++) {
    const speedMult = Math.random() * 1.5 + 0.5;
    stars[i] = {
      r: Math.random() * STAR_SIZE * canvas.width / 2,
      x: Math.floor(Math.random() * canvas.width),
      y: Math.floor(Math.random() * canvas.height),
      xv: xv * speedMult,
      yv: yv * speedMult,
    };
  }

  // set up the animation loop
  let timeDelta;
  let timeLast = 0;
  requestAnimationFrame(loop);

  function loop(timeNow: number) {
    if (!ctx)
      return;

    // calculate the time difference
    timeDelta = timeNow - timeLast;
    timeLast = timeNow;

    // space background
    ctx.fillStyle = COLOR_SPACE;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // draw the stars
    ctx.fillStyle = COLOR_STARS;
    for (let i = 0; i < STAR_NUM; i++) {
      ctx.beginPath();
      ctx.arc(stars[i].x, stars[i].y, stars[i].r, 0, Math.PI * 2);
      ctx.fill();

      // update the star's x position
      stars[i].x += stars[i].xv * timeDelta * 0.001;

      // reposition the star to the other side if it goes off screen
      if (stars[i].x < 0 - stars[i].r)
        stars[i].x = canvas.width + stars[i].r;
      else if (stars[i].x > canvas.width + stars[i].r)
        stars[i].x = 0 - stars[i].r;

      // update the star's y position
      stars[i].y += stars[i].yv * timeDelta * 0.001;

      // reposition the star to the other side if it goes off screen
      if (stars[i].y < 0 - stars[i].r)
        stars[i].y = canvas.height + stars[i].r;
      else if (stars[i].y > canvas.height + stars[i].r)
        stars[i].y = 0 - stars[i].r;
    }

    // call the next frame
    requestAnimationFrame(loop);
  }

  function randomSign() {
    return Math.random() >= 0.5 ? 1 : -1;
  }
});
</script>

<template>
  <div class="hidden md:block bg-cover fixed top-0 left-0 opacity-80 w-full h-full bg-bottom  overflow-hidden z-[-1] bg-[url('/images/item-background.jpg')]" />
  <div id="stars-moving" class="fixed top-0 left-0 opacity-50 w-full h-svh overflow-hidden z-[-1]" />
</template>

<style scoped>
canvas {
    display: block;
}
</style>
