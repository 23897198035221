import { CoreUtil } from "@walletconnect/modal-core";
import WCListing from "~/constants/wc-mobile-mapping";

export default defineNuxtPlugin((app) => {
  const isAndroidReal = navigator.userAgent.toLowerCase().includes("android");
  // CoreUtil.isAndroid = () => false;

  window.open = (function (open) {
    return function (url, _, features) {
      const universalLink = WCListing.getMobileUniversalLink(url as string);
      const currentHost = window.location.host;

      if (isAndroidReal) {
        window.openLink(universalLink);
        return window;
      }

      const finalUrl = universalLink.startsWith("http")
        ? universalLink
        : currentHost.includes("milky-stavax")
        ? `https://account-dev.stavax.io/api/redirect?u=${encodeURIComponent(url as string)}`
        : `https://${currentHost}/api/redirect?u=${encodeURIComponent(url as string)}`;
      return open.call(window, finalUrl, "_self", "noreferrer noopener");
    };
  })(window.open);
});
