import { DAppManifest, TonConnectBridgeType } from "../models";
import { IConnectedAppConnection, IConnectedAppConnectionRemote, IConnectedApp, IMetaData } from "./types";

const getWalletAddress = () => {
  return "UQAjicQTj68m6cNDDKqkg5gt3SJJXGWurS5QsEep52eZ1zCY";
};

export function getConnectionList() {
  const connectedAppStoreList = localStorage.getItem("connected_list") || "[]";

  return JSON.parse(connectedAppStoreList) as IConnectedApp[];
}

export function getManifestSaved(domain: string) {
  const objManifestStr = localStorage.getItem("manifest_saved") || "{}";
  const objManifest = JSON.parse(objManifestStr) as { [x: string]: DAppManifest };

  return objManifest[domain];
}

export function saveManifest(domain: string, manifest: DAppManifest) {
  const objManifestStr = localStorage.getItem("manifest_saved") || "{}";
  const objManifest = JSON.parse(objManifestStr) as { [x: string]: DAppManifest };

  objManifest[domain] = manifest;
  localStorage.setItem("manifest_saved", JSON.stringify(objManifest));
}

export const saveAppConnection = ({
  walletAddress,
  sessionId,
  connection,
  metadata,
}: {
  walletAddress: string;
  sessionId: string;
  connection: IConnectedAppConnection;
  metadata: IMetaData;
}) => {
  const connectionList = getConnectionList();
  const connectedAppStoreListFiltered = connectionList.filter((item) => item.metadata?.domain !== metadata.domain);
  localStorage.setItem("connected_list", JSON.stringify([...connectedAppStoreListFiltered, { walletAddress, sessionId, connection, metadata }]));
};

export const removeConnectedApp = (domain: string) => {
  const connectionList = getConnectionList();

  const connectedAppStoreListFiltered = connectionList.filter((item) => item.metadata.domain !== domain);
  localStorage.setItem("connected_list", JSON.stringify(connectedAppStoreListFiltered));
};

export const removeRemoteConnection = (isTestnet: boolean, connectedApp: IConnectedApp, connection: IConnectedAppConnectionRemote) => {
  const currentWalletAddress = getWalletAddress();
};

export const findConnectedAppByUrl = (domain: string): IConnectedApp | null => {
  const connectionList = getConnectionList();

  return connectionList.find((item: any) => item.domain === domain) ?? null;
};

export const findConnectedByClientId = (clientId: string, walletIdentifier: string): IConnectedApp | null => {
  const connectedAppsList = getConnectionList();
  console.log("sessionId", clientId, connectedAppsList);

  if (!connectedAppsList.length) {
    return null;
  }

  const connectedApp = connectedAppsList.find((app) => {
    if (app.connection?.type === TonConnectBridgeType.Remote && app.connection.clientSessionId === clientId) {
      return true;
    }

    return false;
  });

  return connectedApp || null;
};
