import { address } from "@ton/core";
import { find } from "lodash";
import moment from "moment";
import { isAddress } from "viem";
import { toast } from "~/components/ui/toast";
import { IHistoryItem } from "~/services/api/transaction/type";

export function shortAddress(strAddress?: string, numOfShow = 6) {
  if (!strAddress) return "";
  const tmpAddress = strAddress.trim();
  if (tmpAddress.length <= 15) return tmpAddress;
  let isTonWallet = false;
  try {
    isTonWallet = !!address(tmpAddress);
  } catch (error) {}
  if (isAddress(tmpAddress) || isTonWallet) {
    return `${tmpAddress.substring(0, numOfShow)}...${tmpAddress.substring(tmpAddress.length - numOfShow, tmpAddress.length)}`;
  }

  const begin = tmpAddress.substring(0, 15);
  return `${begin}${tmpAddress.length > 15 ? "..." : ""}`;
}

export const formatNumber = (number?: string | number, behind = 5) => {
  if (number === undefined) return "";
  if (number === "0" || Number(number) === 0) {
    return "0";
  }
  const min = 1 / Math.pow(10, behind);
  if (Math.abs(Number(number)) < min) return `< 0.${"0".repeat(behind - 1)}1`;
  let value = number.toString();
  if (value.includes(".") || value.includes(",")) {
    value = value.replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    const [frontDot, behindDot] = value.split(".");
    const tmpSub = (behindDot ? Number(`0.${behindDot}`) + 0.0000001 : "").toString().split(".")[1] || "";

    const sub = tmpSub.substring(0, behind).replace(/(0+)$/, "");

    value = `${frontDot}${sub ? "." : ""}${sub}`;
  } else {
    value = value.replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }

  return value;
};

export function copyToClipboard(str: string) {
  const textarea = document.createElement("textarea");
  const text = str || "";
  textarea.textContent = text;
  textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand("copy"); // Security exception may be thrown by some browsers.
    toast({
      title: "Copied to clipboard",
      duration: 3000,
    });
  } catch (ex) {
    console.warn("Copy to clipboard failed.", ex);
    toast({
      title: "Coppy failed",
      duration: 3000,
      variant: "destructive",
    });
  } finally {
    document.body.removeChild(textarea);
  }
}

export function getTimeDurationLabel(time: number) {
  if (time === 0) return "0s";
  if (time < 60) return `${time}s`;
  const m = Math.floor(time / 60);
  const h = Math.floor(m / 60);
  return `${h}h ${m % 60 ? `${m % 60}m` : ""}`;
}

export function formatSeconds(sec?: number): string {
  if (!sec) {
    return "";
  }
  if (sec < 60) {
    return `${sec.toString().padStart(2, "0")}s`;
  }
  if (sec < 3600) {
    return `${Math.floor(sec / 60)
      .toString()
      .padStart(2, "0")}m${formatSeconds(sec % 60)}`;
  }
  return `${Math.floor(sec / 3600)
    .toString()
    .padStart(2, "0")}h${formatSeconds(sec % 3600)}`;
}

export function getHistoryLabelName(type: string, item?: IHistoryItem) {
  const method = item?.contract_payload?.method;
  if (method) {
    if (method === "approval") return "Token Approval";
  }
  if (item?.multi_send_data?.recipients) return "Multi Send";
  if (type === "withdraw") return "Send";
  if (type === "deposit") return "Receive";
  if (type === "contract_call") {
    if (item?.activity === "approval") return "Approval";
  }
  return "Contract";
}

export const formatNotationNumber = (number?: string | number, customBehind?: number) => {
  let behind = 1;
  if (typeof number === "string" && number.length === 0) return "--";
  if (typeof number === "undefined") return "--";
  if (number === null) return "--";
  if (!number) return "0";
  if (!/[0-9]+/g.test(number.toString())) return number.toString();
  if (Number.isNaN(Number(number))) return number.toString();
  if (Math.abs(Number(number)) < 1000) behind = 2;
  if (Math.abs(Number(number)) < 100) behind = 3;
  if (Math.abs(Number(number)) < 10) behind = 4;
  if (customBehind) behind = customBehind;

  const num = Number(number.toString().replace(/[,]/g, ""));
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return Math.abs(num) > 9999 ? `${num < 0 ? "-" : ""}${formatter.format(Math.abs(num))}` : formatNumber(num, behind);
};

export function getAddressFromScan(txt: string) {
  if (txt.includes("/")) {
    const splits = txt.split("/");
    return splits[splits.length - 1];
  }
  if (txt.includes(":")) {
    const splits = txt.split(":");
    return splits[splits.length - 1];
  }
  return txt;
}
