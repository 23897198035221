import { useMPCKeyShare } from "#staxmpc/composables/mpcKeyshare";
import { encodeHex } from "#staxmpc/lib/encode";
import { publicKeyConvert } from "secp256k1";
import { hashMessage, hashTypedData, type Hex } from "viem";
import { publicKeyToAddress } from "viem/accounts";
import { WALLET_TYPE } from "./type";
import { BLOCKCHAIN_TYPE } from "../api/auth/type";

export function useTraditionalWallet(storePwd: string) {
  const { getUser } = useAuthStore();

  const smart_data = computed(() => getUser().smart_wallets.find((w) => w.blockchain === "evm"));
  const wallet = useMPCKeyShare(storePwd);

  const walletAddress = computed(() => {
    if (!wallet.keyshare.value) {
      return "";
    }
    return publicKeyToAddress(("0x" + encodeHex(publicKeyConvert(wallet.keyshare.value!.publicKey, false))) as Hex);
  });

  async function signMessage(message: string, option?: { hash?: boolean }) {
    console.log("signMessage", wallet.keyshare.value);
    if (!wallet || !wallet.keyshare.value) {
      throw new Error("MPC Wallet not found");
    }
    const hash = option?.hash ? (message as Hex) : hashMessage(message);

    const signature = await wallet.signMessage(hash);

    return signature as `0x${string}`;
  }
  async function signTypedData(payload: any, provider = "EVM") {
    console.log("signTypedData", wallet.keyshare.value);

    if (!wallet || !wallet.keyshare.value) {
      throw new Error("MPC Wallet not found");
    }
    const signature = await wallet.signMessage(hashTypedData(payload as any));

    return signature as `0x${string}`;
  }

  return {
    address: computed(() => walletAddress.value),
    get smart_data() {
      return smart_data.value;
    },
    get smart_address() {
      return walletAddress.value;
    },
    algo: computed(() => wallet.keyshare.value?.algo),
    wallet_type: "evm_mpc" as WALLET_TYPE,
    blockchain: "traditional" as BLOCKCHAIN_TYPE,
    get name() {
      if (!wallet.keyshare.value) {
        return "";
      }
      return getUser().user_wallets.find((w) => w.address === (walletAddress.value as Hex))?.name || "";
    },
    is_smart: false,

    signMessage,
    signTypedData,
    clear: () => {},
    generateWallet: (pwd: string, hint?: string) => Promise.resolve(),
    getSavedWallet: () => Promise.resolve(),
  };
}
