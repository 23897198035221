class EventIdService {
  private readonly eventIdStoreKey = "ton-connect-service-event-id";

  private eventId: number = 0;

  constructor() {
    this.eventId = Number(localStorage.getItem(this.eventIdStoreKey) || "0");
  }

  public getId() {
    this.eventId++;

    localStorage.setItem(this.eventIdStoreKey, String(this.eventId));

    return this.eventId;
  }
}

export const TCEventID = new EventIdService();
