import { Address, beginCell, Cell, external, internal, storeMessage, MessageRelaxed, SendMode, loadStateInit, fromNano } from "@ton/core";
import { OpCodes, Signer, WalletContract } from "./contractService";
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { TonClient } from "@ton/ton";

export type AnyAddress = string | Address;

export interface TransferParams {
  seqno?: number;
  timeout?: number;
  sendMode?: number;
  messages: MessageRelaxed[];
}

export function tonAddress(address: AnyAddress) {
  if (typeof address === "string") {
    return Address.parse(address);
  }

  return address;
}

export class TransactionService {
  public static TTL = 5 * 60;

  public static getTimeout() {
    return Math.floor(Date.now() / 1e3) + TransactionService.TTL;
  }

  public static externalMessage(contract: WalletContract, seqno: number, body: Cell) {
    return beginCell()
      .storeWritable(
        storeMessage(
          external({
            to: contract.address,
            init: seqno === 0 ? contract.init : undefined,
            body: body,
          })
        )
      )
      .endCell();
  }

  private static getBounceFlagFromAddress(address: string) {
    try {
      return Address.isFriendly(address) ? Address.parseFriendly(address).isBounceable : true;
    } catch {
      return true;
    }
  }

  private static parseStateInit(stateInit?: string) {
    if (!stateInit) {
      return;
    }
    const { code, data } = loadStateInit(Cell.fromBase64(stateInit).asSlice());
    return { code, data };
  }

  static parseSignRawMessages(messages: any[], customExcessesAccount?: string | null) {
    return messages.map((message) => {
      let payload = message.payload && Cell.fromBase64(message.payload);
      if (payload && customExcessesAccount) {
        payload = TransactionService.rebuildBodyWithCustomExcessesAccount(payload, customExcessesAccount);
      }
      const internalMsg = internal({
        to: Address.parse(message.address).toString({ bounceable: false }),
        value: BigInt(message.amount),
        body: payload,
        bounce: this.getBounceFlagFromAddress(message.address),
        init: TransactionService.parseStateInit(message.stateInit),
      });
      return internalMsg;
    });
  }

  static rebuildBodyWithCustomExcessesAccount(payload: Cell, customExcessesAccount: string) {
    const slice = payload.beginParse();
    const opCode = slice.loadUint(32);
    let builder = beginCell();

    switch (opCode) {
      case OpCodes.STONFI_SWAP:
        builder = builder
          .storeUint(OpCodes.STONFI_SWAP, 32)
          .storeAddress(slice.loadAddress())
          .storeCoins(slice.loadCoins())
          .storeAddress(slice.loadAddress());

        if (slice.loadBoolean()) {
          slice.loadAddress();
        }

        return builder.storeBit(1).storeAddress(Address.parse(customExcessesAccount)).endCell();
      case OpCodes.NFT_TRANSFER:
        builder = builder.storeUint(OpCodes.NFT_TRANSFER, 32).storeUint(slice.loadUint(64), 64).storeAddress(slice.loadAddress());

        slice.loadMaybeAddress();

        while (slice.remainingRefs) {
          builder = builder.storeRef(slice.loadRef());
        }

        return builder.storeAddress(Address.parse(customExcessesAccount)).storeBits(slice.loadBits(slice.remainingBits)).endCell();
      case OpCodes.JETTON_TRANSFER:
        builder = builder
          .storeUint(OpCodes.JETTON_TRANSFER, 32)
          .storeUint(slice.loadUint(64), 64)
          .storeCoins(slice.loadCoins())
          .storeAddress(slice.loadAddress());

        slice.loadMaybeAddress();

        while (slice.remainingRefs) {
          const forwardCell = slice.loadRef();
          // recursively rebuild forward payloads
          builder = builder.storeRef(this.rebuildBodyWithCustomExcessesAccount(forwardCell, customExcessesAccount));
        }

        return builder.storeAddress(Address.parse(customExcessesAccount)).storeBits(slice.loadBits(slice.remainingBits)).endCell();
      default:
        return payload;
    }
  }

  static async createTransfer({
    contract,
    signer,
    transferParams,
    autoSend,
  }: {
    contract: WalletContract;
    signer: Signer;
    transferParams: TransferParams;
    autoSend?: boolean;
  }) {
    const endpoint = await getHttpEndpoint();
    const _client = new TonClient({
      endpoint: endpoint,
    });
    const openedContract = _client.open(contract);
    const seqno = transferParams.seqno || (await openedContract.getSeqno());
    const transfer = await contract.createTransfer({
      seqno: seqno,
      signer,
      messages: transferParams.messages,
    });

    const externalMessage = TransactionService.externalMessage(contract, seqno, transfer);
    const signerExternal = externalMessage.toBoc();
    if (autoSend) {
      await _client.sendFile(signerExternal);
    }
    return signerExternal.toString("hex");
  }

  static async sendTransfer(boc: Buffer) {
    const endpoint = await getHttpEndpoint();
    const _client = new TonClient({
      endpoint: endpoint,
    });

    return _client.sendFile(boc);
  }
}
