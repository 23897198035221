import { defineStore } from "pinia";
import { IRemoteConfig, ISessionInfo, ISupportToken } from "~/services/api/session/type";

export interface ISetting {
  remoteConfig: IRemoteConfig;
  currentTokenSelected?: ISupportToken;
  sessionInfo: ISessionInfo;
  project_id: string;
}

export const useSettingStore = defineStore("setting", () => {
  const setting = ref<ISetting>({ remoteConfig: {} } as ISetting);

  function setSetting(key: string, value: any) {
    // @ts-ignore
    setting.value[key] = value;
  }

  function getSetting() {
    return setting.value;
  }

  return {
    setSetting,
    getSetting,
    setting: computed(() => setting.value),
  };
});
