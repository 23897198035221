import axios from "axios";

const api = `https://api.binance.com/api/v1/ticker/price?symbol=`;

export const binancePrice: { [symbol: string]: { price: string; timeToGet: number } } = {};
const DURATION_TO_GET = 5 * 60 * 1000;

async function getBiancePrice(symbol: string) {
  try {
    const data = await axios.get(`${api}${symbol.toUpperCase()}USDT`);
    const price = Number(data.data.price).toString();
    binancePrice[symbol] = {
      price,
      timeToGet: new Date().getTime(),
    };
    return price;
  } catch (error) {
    console.log("getBiancePrice er", error);
    return "0";
  }
}

export function getTokenPrice(symbol: string) {
  if (symbol === "USDT") return "1";
  if (binancePrice[symbol]) {
    if (new Date().getTime() - binancePrice[symbol].timeToGet < DURATION_TO_GET) {
      return binancePrice[symbol].price;
    }
  }
  return getBiancePrice(symbol);
}
