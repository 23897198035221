import {
  AppRequest,
  ConnectEvent,
  ConnectRequest,
  CONNECT_EVENT_ERROR_CODES,
  RpcMethod,
  SEND_TRANSACTION_ERROR_CODES,
  SessionCrypto,
  WalletResponse,
  CHAIN,
} from "@tonconnect/protocol";
import axios from "axios";
import TonWeb from "tonweb";
import { MIN_PROTOCOL_VERSION, TonConnectBridgeType, tonConnectDeviceInfo } from "./config";
import { ConnectReplyBuilder } from "./ConnectReplyBuilder";
import { TCEventID } from "./EventID";
import { DAppManifest } from "./models";
import { TonConnectRemoteBridge } from "./TonConnectRemoteBridgeService";
import { ConnectEventError } from "./ConnectEventError";
import { SendTransactionError } from "./SendTransactionError";
import { getDomainFromURL, getTransactionHash } from "./utils";
import { findConnectedByClientId, IConnectedApp, removeConnectedApp } from "./connectedApps";
import { IWalletBase, useWalletStore } from "../WalletManager/store";
import { Address, beginCell, fromNano, storeStateInit, toNano } from "@ton/core";
import { emulateTransactionBoc, TON_CHAIN, TON_CHAIN_ID } from ".";
import { toast } from "~/components/ui/toast";
import { TransactionService } from "./transactionService";
import { TonClient, WalletContractV4, Transaction } from "@ton/ton";
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { ITxData } from "../api/transaction/type";

class TonConnectService {
  currentManifest: DAppManifest | null = null;
  manifestUrl: string = "";
  getCurrentWallet(walletAddress?: string) {
    const wallets = useWalletStore();
    if (!walletAddress) return wallets.getCurrentWallet();
    const wallet = wallets.findWalletWithAddress(walletAddress);
    return wallet;
  }
  checkProtocolVersionCapability(protocolVersion: number) {
    if (typeof protocolVersion !== "number" || protocolVersion < MIN_PROTOCOL_VERSION) {
      throw new ConnectEventError(
        CONNECT_EVENT_ERROR_CODES.BAD_REQUEST_ERROR,
        `Protocol version ${String(protocolVersion)} is not supported by the wallet app`
      );
    }
  }

  verifyConnectRequest(request: ConnectRequest) {
    if (!(request && request.manifestUrl && request.items?.length)) {
      throw new ConnectEventError(CONNECT_EVENT_ERROR_CODES.BAD_REQUEST_ERROR, "Wrong request data");
    }
  }

  async getManifest(request: ConnectRequest) {
    try {
      console.log("request.manifestUrl", request.manifestUrl);
      if (request.manifestUrl === this.manifestUrl && this.currentManifest) {
        return this.currentManifest;
      }
      const { data: manifest } = await axios.get<DAppManifest>(request.manifestUrl);

      const isValid = manifest && typeof manifest.url === "string" && typeof manifest.name === "string" && typeof manifest.iconUrl === "string";

      if (!isValid) {
        throw new ConnectEventError(CONNECT_EVENT_ERROR_CODES.MANIFEST_CONTENT_ERROR, "Manifest is not valid");
      }

      if (manifest.iconUrl) {
        console.log("manifest", manifest);
        // FastImage.preload([{ uri: manifest.iconUrl }]);
      }
      this.currentManifest = manifest;
      this.manifestUrl = request.manifestUrl;
      return manifest;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new ConnectEventError(CONNECT_EVENT_ERROR_CODES.MANIFEST_NOT_FOUND_ERROR, `Can't get ${request.manifestUrl}`);
      }

      throw error;
    } finally {
      // Toast.hide();
    }
  }

  async connect(protocolVersion: number, request: ConnectRequest): Promise<ConnectEvent> {
    try {
      const tonWallet = this.getCurrentWallet();
      if (!tonWallet) throw new Error("Wallet not found");
      this.checkProtocolVersionCapability(protocolVersion);

      this.verifyConnectRequest(request);

      const manifest = await this.getManifest(request);

      if (!manifest || getDomainFromURL(manifest.url) === "tonkeeper.com") {
        throw new ConnectEventError(CONNECT_EVENT_ERROR_CODES.BAD_REQUEST_ERROR, "Bad request");
      }

      const replyItems = await new ConnectReplyBuilder(request, manifest).createReplyItems(
        this.getCurrentWallet()?.address || "",
        this.getCurrentWallet()?.getSigner(),
        new Uint8Array(tonWallet.publicKey),
        beginCell().store(storeStateInit(tonWallet.contract.init)).endCell().toBoc().toString("base64"),
        false
      );
      try {
        return {
          id: TCEventID.getId(),
          event: "connect",
          payload: {
            items: replyItems,
            device: tonConnectDeviceInfo,
          },
        };
      } catch {
        throw new ConnectEventError(CONNECT_EVENT_ERROR_CODES.USER_REJECTS_ERROR, "Wallet declined the request");
      }
    } catch (error) {
      console.log("connect error", error);
      if (error instanceof ConnectEventError) {
        throw error;
      }

      // @ts-ignore
      throw new ConnectEventError(CONNECT_EVENT_ERROR_CODES.UNKNOWN_ERROR, error?.message);
    }
  }

  async checkRequestTransaction(request: AppRequest<"sendTransaction">) {
    const params = JSON.parse(request.params[0]);

    const isValidRequest =
      params &&
      typeof params.valid_until === "number" &&
      Array.isArray(params.messages) &&
      params.messages.every((msg: any) => !!msg.address && !!msg.amount);

    const isValidNetwork = params.network ? params.network === TON_CHAIN_ID : true;
    const isValidFrom = !!params.from;

    if (!isValidRequest) {
      toast({
        description: "Invalid request",
        duration: 3000,
      });

      throw new SendTransactionError(request.id, SEND_TRANSACTION_ERROR_CODES.BAD_REQUEST_ERROR, "Bad request");
    }
    if (!isValidNetwork) {
      toast({
        description: "Stavax do not support network",
        duration: 3000,
      });

      throw new SendTransactionError(request.id, SEND_TRANSACTION_ERROR_CODES.BAD_REQUEST_ERROR, "Wrong network");
    }

    if (!isValidFrom) {
      toast({
        description: "Wrong from parameter",
        duration: 3000,
      });

      throw new SendTransactionError(request.id, SEND_TRANSACTION_ERROR_CODES.BAD_REQUEST_ERROR, 'Wrong "from" parameter');
    }

    const { valid_until, messages } = params;

    if (!valid_until) {
      throw new SendTransactionError(request.id, SEND_TRANSACTION_ERROR_CODES.BAD_REQUEST_ERROR, "Request timed out");
    }

    const wallet = this.getCurrentWallet();
    if (!wallet) throw new SendTransactionError(request.id, SEND_TRANSACTION_ERROR_CODES.BAD_REQUEST_ERROR, "Wallet not found");

    const totalAmount = (messages as { payload?: string; amount: string }[]).reduce((total, item) => {
      const msgAmount = fromNano(item.amount);
      return total + Number(msgAmount);
    }, 0);
    const nativeBalance = (await wallet.getNativeBalance?.()) || 0;
    if (totalAmount > nativeBalance) {
      toast({
        description: `Insufficient balance. You need a total of ${totalAmount} TON to proceed with the transaction`,
        duration: 3000,
      });
      throw new SendTransactionError(request.id, SEND_TRANSACTION_ERROR_CODES.BAD_REQUEST_ERROR, "User does not have enough balance");
    }
  }

  async getRequestTransactionInfor(request: AppRequest<"sendTransaction">): Promise<ITxData> {
    await this.checkRequestTransaction(request);

    const params = JSON.parse(request.params[0]);
    const { valid_until, messages } = params;

    const boc = await this.getTransactionBoc(request);
    const emulate = await emulateTransactionBoc(boc);
    return {
      to: "",
      recipients: messages.map((msg: any) => {
        return {
          address: Address.parse(msg.address).toString({ bounceable: false }),
          amount: fromNano(msg.amount),
        };
      }),
      value: "",
      networkInfo: TON_CHAIN,
      ...emulate,
    };
  }

  async getTransactionBoc(request: AppRequest<"sendTransaction">, autoSend?: boolean) {
    const wallet = this.getCurrentWallet();
    const params = JSON.parse(request.params[0]);
    const account = WalletContractV4.create({
      workchain: 0,
      publicKey: wallet!.publicKey,
    });

    const boc = await TransactionService.createTransfer({
      contract: account,
      signer: await wallet!.getSigner(),
      transferParams: {
        messages: TransactionService.parseSignRawMessages(params.messages),
      },
      autoSend,
    });
    return boc;
  }

  async handleDisconnectRequest(request: AppRequest<"disconnect">, connectedApp: IConnectedApp): Promise<WalletResponse<"disconnect">> {
    removeConnectedApp(connectedApp.metadata.domain);

    return {
      id: request.id,
      result: {},
    };
  }

  async fetchTransactionHashAgain() {
    const wallet = this.getCurrentWallet();
    const account = WalletContractV4.create({
      workchain: 0,
      publicKey: wallet!.publicKey,
    });
    const { tx_hash } = await getTransactionHash(account);
    return tx_hash;
  }

  async sendTransaction(request: AppRequest<"sendTransaction">): Promise<any> {
    try {
      console.log("sendTransaction", request);
      const boc = await this.getTransactionBoc(request, true);
      const wallet = this.getCurrentWallet();
      const account = WalletContractV4.create({
        workchain: 0,
        publicKey: wallet!.publicKey,
      });

      const { tx_hash, transaction } = await getTransactionHash(account);
      const params = JSON.parse(request.params[0]);
      const { valid_until, messages } = params;
      const totalAmount = (messages as { payload?: string; amount: string }[]).reduce((total, item) => {
        const msgAmount = fromNano(item.amount);
        return total + Number(msgAmount);
      }, 0);
      console.log("totalAmount", totalAmount);
      return {
        boc,
        txhash: tx_hash,
        transaction,
        to: Address.parse(messages[0].address).toString({ bounceable: false }),
        totalAmount,
      };
    } catch (error) {
      if (error instanceof SendTransactionError) {
        return error;
      }
      // @ts-ignore
      return new SendTransactionError(request.id, SEND_TRANSACTION_ERROR_CODES.UNKNOWN_ERROR, error?.message);
    }
  }
}

export const TonConnect = new TonConnectService();
