import { ConnectItemReply, KeyPair } from "@tonconnect/protocol";

export interface IConnectQrQuery {
  v: string;
  r: string;
  id: string;
  ret: ReturnStrategy;
}

export interface DAppManifest {
  url: string;
  name: string;
  iconUrl: string;
  termsOfUseUrl?: string;
  privacyPolicyUrl?: string;
}

export enum TonConnectBridgeType {
  Remote = "remote",
  Injected = "injected",
}

export interface IConnectedAppConnectionRemote {
  type: TonConnectBridgeType.Remote;
  sessionKeyPair: KeyPair;
  clientSessionId: string;
  sessionId: string;
  replyItems: ConnectItemReply[];
}

export interface IConnectedAppConnectionInjected {
  type: TonConnectBridgeType.Injected;
  replyItems: ConnectItemReply[];
  clientSessionId?: string;
  sessionId?: string;
  sessionKeyPair?: KeyPair;
}

export type IConnectedAppConnection = IConnectedAppConnectionRemote | IConnectedAppConnectionInjected;

export type WithWalletIdentifier<T> = T & { walletIdentifier: string };

export type ReturnStrategy = "back" | "none" | string;

export type TEmulateEvent = "SmartContractExec" | "JettonTransfer" | "JettonSwap" | "TonTransfer";

export interface IJesttonSwap {
  amount_in: string;
  amount_out: string;
  jetton_master_in: {
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    image: string;
    verification: string;
  };
}

export interface IJesttonElement {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  image: string;
  verification: string;
}

export interface IEmulateRisk {
  transfer_all_remaining_balance: boolean;
  ton: number;
  jettons: {
    jetton: IJesttonElement;
    quantity: string;
  }[];
}
