import { useMPCKeyShare } from "#staxmpc/composables/mpcKeyshare";
import { encodeHex } from "#staxmpc/lib/encode";
import { publicKeyConvert } from "secp256k1";
import { hashMessage, hashTypedData, hexToBytes, type Hex } from "viem";
import { publicKeyToAddress } from "viem/accounts";
import { WALLET_TYPE } from "./type";
import { BLOCKCHAIN_TYPE } from "../api/auth/type";
import Crypto from "../encrypt/EncryptionService";
import { createSmartWallet, getUserInfo } from "../api/auth/api";
import { useWebAppCloudStorage } from "vue-tg";
import { getTgCloudData, updateTgCloudData } from "../api/drive/mpc_api";
import { Cell, OpenedContract, TonClient, WalletContractV4 } from "@ton/ton";
import { SignAlgo } from "#staxmpc/lib/dkls";
import PasswordService from "../security/PasswordService";
import { KeyPair } from "@ton/crypto";
import { createTonUtils } from "../tonkeeper/utils";

const CLOUD_STORE_KEY = "EVM_MPC_SHARE1";
const MPC_CLOUD_META_KEY = "EVM_MPC_WALLET_META";

export function useTonMPCWallet(storePwd: string) {
  const { getUser, setUser } = useAuthStore();

  const wallet = useMPCKeyShare(storePwd, SignAlgo.EdDSA);
  const mpcStore = useMPCStore(storePwd);
  const tgCloud = useWebAppCloudStorage();
  const tgCloudBackuped = ref(false);
  const keypair = ref<KeyPair | null>(null);
  const contract = ref<OpenedContract<WalletContractV4> | null>(null);
  const client = ref<TonClient | null>(null);
  const tonUtilRef = ref<ReturnType<typeof createTonUtils> | null>(null);

  const account = ref<WalletContractV4 | null>(null);

  function getTonUtils() {
    if (!account.value) return null;
    if (tonUtilRef.value) return tonUtilRef.value;
    tonUtilRef.value = createTonUtils({
      // @ts-ignore
      account: account.value,
      // @ts-ignore
      keypair: keypair.value,
      signer: async (message: Cell) => {
        const buff = message.hash();
        console.log("encodeHex(new Uint8Array(buff))", encodeHex(new Uint8Array(buff)));
        const signStr = await wallet.signMessage(encodeHex(new Uint8Array(buff)));
        const decodeSign = hexToBytes(signStr as Hex);
        return Buffer.from(decodeSign);
      },
    });
    return tonUtilRef.value;
  }

  function getSigner() {
    return async (message: Cell) => {
      console.log("signer signer", message);
      const buff = message.hash();
      console.log("encodeHex(new Uint8Array(buff))", encodeHex(new Uint8Array(buff)));
      const signStr = await wallet.signMessage(encodeHex(new Uint8Array(buff)));
      const decodeSign = hexToBytes(signStr as Hex);
      return Buffer.from(decodeSign);
    };
  }

  async function getNativeBalance() {
    return getTonUtils()?.getNativeBalance();
  }

  async function sendTransaction(params: { to: string; value: string; contract_address: string }) {
    if (!account.value) throw new Error("Account not found");
    return getTonUtils()?.sendTransaction(params);
  }

  const walletAddress = computed(() => {
    if (!wallet.keyshare.value) {
      return "";
    }

    const walletContract = WalletContractV4.create({
      workchain: 0,
      publicKey: Buffer.from(wallet.keyshare.value.publicKey),
    });
    account.value = walletContract;

    const eoa_address = walletContract.address.toString({
      bounceable: false,
    });
    if (!getUser().user_wallets.find((w) => w.address === eoa_address)) return "";

    return eoa_address;
  });

  async function signMessage(message: string, option?: { hash?: boolean }) {
    return "0x0" as `0x${string}`;
  }
  async function signTypedData(payload: any, provider = "EVM") {
    return "0x0" as `0x${string}`;
  }

  async function clearCloudStore() {}

  async function getSavedWallet() {
    try {
      const metaSaved = (await tgCloud.getStorageItem(MPC_CLOUD_META_KEY)) || "";
      const meta = metaSaved ? JSON.parse(metaSaved) : null;
      if (mpcStore.isEmpty) {
        mpcStore.lock();

        if (meta) {
          console.log("meta", meta);
          const arrSaved = [];
          for (let i = 0; i < 9; i++) {
            arrSaved.push(tgCloud.getStorageItem(`${CLOUD_STORE_KEY}_${meta.version}_${i}`));
          }
          const arrSaveFromServer = getTgCloudData();
          const arrPartShare1 = (await Promise.all([...arrSaved, arrSaveFromServer])).filter((item) => !!item);
          if (arrPartShare1.length !== 10) {
            clearCloudStore();
            throw new Error("Part of share1 exist null");
          }

          const share1 = arrPartShare1.join("");

          await mpcStore.importShare1(share1, storePwd);
          tgCloudBackuped.value = true;
        }
      } else {
        if (meta && wallet.keyshare.value?.keyId !== meta.key_id) {
          // await clear(storePwd);
          // await getSavedWallet();
        }
      }
    } catch (error) {
      console.log("khong co vi ton", error);
    }
  }

  async function checkSavedToTgCloud() {
    try {
      if (tgCloudBackuped.value) return true;
      const metaSaved = (await tgCloud.getStorageItem(MPC_CLOUD_META_KEY)) || "";
      if (metaSaved && !mpcStore.isEmpty) {
        const meta = JSON.parse(metaSaved);
        tgCloudBackuped.value = meta.key_id === wallet.keyshare.value?.keyId;
      } else tgCloudBackuped.value = false;
      return tgCloudBackuped.value;
    } catch (error) {
      console.log("checkSavedToTgCloud", error);
      return false;
    }
  }
  async function saveWalletToCloud(callbackProgress?: (progress: number) => void) {
    const share1 = await mpcStore.exportShare1(storePwd);
    if (!share1) return false;
    let count = 0;
    let version = "v1";
    const JUMP = 4000;
    const totalPart = Math.ceil(share1.length / JUMP);
    console.log("saveWalletToCloud", share1.length, totalPart);

    while (count < 9) {
      const res = await tgCloud.setStorageItem(`${CLOUD_STORE_KEY}_${version}_${count}`, share1.slice(JUMP * count, JUMP * (count + 1)));

      if (!res) throw new Error("Failed to save share1 to telegram cloud");
      const progress = ((count + 1) / (totalPart + 1)) * 100;
      callbackProgress?.(progress);
      console.log("uploadCloudProgress", progress);
      ++count;
    }

    const res = await updateTgCloudData(share1.slice(JUMP * 9, share1.length));
    if (!res) throw new Error("Failed to update cloud data");
    tgCloud.setStorageItem(
      MPC_CLOUD_META_KEY,
      JSON.stringify({
        version,
        num_of_part: 9,
        share_length: share1.length,
        cloud_key: CLOUD_STORE_KEY,
        key_id: wallet.keyshare.value?.keyId || "",
      })
    );
    tgCloudBackuped.value = true;
    callbackProgress?.(100);
    console.log("save mpc to tg cloud done");
    return true;
  }

  async function generateWallet(pwd: string, hint: string) {
    if (!mpcStore.isUnlocked) {
      await mpcStore.unlock(storePwd);
    }
    if (walletAddress.value) return wallet;

    const [share1, share2, _] = await wallet.generateWallet();

    const publicKey = share1.share.publicKey();

    const walletContract = WalletContractV4.create({
      workchain: 0,
      publicKey: Buffer.from(publicKey),
    });
    const eoa_address = walletContract.address.toString({ bounceable: false });

    const enc = Crypto.encrypt(eoa_address, pwd || PasswordService.getPassword());
    const key_id = encodeHex(share1.share.keyId()) || "";

    await createSmartWallet(enc, eoa_address, "", "ton", key_id);

    // save to server success
    await mpcStore.setSharesByAlgo(SignAlgo.EdDSA, share1, share2);

    const newUser = await getUserInfo();
    setUser(newUser);
    return wallet;
  }

  async function clear(id: string) {
    if (mpcStore.isLocked) {
      await mpcStore.unlock(id);
    }
    await mpcStore.clear(id);
  }

  return {
    get address() {
      return walletAddress.value;
    },
    get smart_data() {
      return {};
    },
    get smart_address() {
      return walletAddress.value;
    },
    algo: computed(() => wallet.keyshare.value?.algo),
    wallet_type: "ton_mpc" as WALLET_TYPE,
    blockchain: "ton" as BLOCKCHAIN_TYPE,
    get name() {
      return (
        getUser().user_wallets.find((w) => {
          if (!wallet.keyshare.value) {
            return "";
          }

          const walletContract = WalletContractV4.create({
            workchain: 0,
            publicKey: Buffer.from(wallet.keyshare.value.publicKey),
          });
          account.value = walletContract;

          const eoa_address = walletContract.address.toString({
            bounceable: false,
          });
          return w.address === eoa_address || w.address === walletContract.address.toString();
        })?.name || ""
      );
    },
    smart_name: "Ton MPC Wallet",
    is_smart: false,
    getSavedWallet,
    signMessage,
    generateWallet,
    signTypedData,
    clear,
    saveWalletToCloud,
    checkSavedToTgCloud,
    sendTransaction,
    getNativeBalance,
    get tgCloudBackuped() {
      return tgCloudBackuped.value;
    },
    publicKey: computed(() => Buffer.from(wallet.keyshare.value!.publicKey)),
    getSigner,
  };
}
