import { toast } from "~/components/ui/toast";
import Fetch from "..";
import { ISupportToken } from "../session/type";
import { ISearchToken } from "./type";
import errorHandler from "../ErrorHandler";

export async function postImportToken(item: any) {
  try {
    const { data } = await Fetch.post<{ data: any }>(`@api/tokens/import`, item);
    return data.data;
  } catch (error) {
    console.log("postImportToken er", error);
    return toast({
      description: errorHandler(error).getAllMessagesString(),
      duration: 3000,
    });
  }
}

export async function postDisableToken(item: ISupportToken) {
  try {
    const { data } = await Fetch.post<{ data: any }>(`@api/tokens/disable`, item);
    return data.data;
  } catch (error) {
    console.log("postDisableToken er", error);
    return null;
  }
}

export async function getUserTokens(): Promise<ISupportToken[]> {
  try {
    const { data } = await Fetch.get<{ data: ISupportToken[] }>(`@api/tokens/me`);
    return data.data;
  } catch (error) {
    console.log("getUserTokens er", error);
    return [];
  }
}

export async function fetchSearchTokens(search: string): Promise<ISearchToken[]> {
  try {
    const { data } = await Fetch.get<{ data: ISearchToken[] }>(`@api/tokens/search`, {
      query: {
        input: search,
      },
    });
    return data.data;
  } catch (error) {
    console.log("fetchSearchTokens er", error);
    return [];
  }
}
