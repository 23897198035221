<script setup lang="ts"></script>
<template>
  <div class="flex flex-col w-full h-screen lg:overflow-hidden no-scrollbar">
    <div
      class="fixed top-[-190px] left-[0px] w-[523px] h-[523px] z-[0] opacity-[0.3]"
      style="background: radial-gradient(circle at 0%, red 0%, black 50%)"
    />
    <div
      class="relative flex-1 mx-auto w-full md:max-w-[720px] lg:rounded-lg container lg:no-scrollbar bg-fixed"
      style="background: radial-gradient(circle at top left, #451717 0%, black 40%)"
    >
      <div class="w-full h-full">
        <slot />
      </div>
    </div>
    <div
      class="absolute right-[40%] bottom-[0] z-[-1]"
      style="width: 523px; height: 523px; background: #ff0002; box-shadow: 379px 379px 379px; border-radius: 9999px; filter: blur(379px)"
    />
  </div>
</template>
